import React from "react"
import { useSelector } from "react-redux"
import BarChart from "../partials/charts/BarChart"
import HtmlBarChart from "../partials/charts/HtmlBarChart"
import DoughnutChart from "../partials/charts/DoughnutChart"

const ExamResultCard = ({ sessionData, classrooms, printMode = false }) => {
    const { language } = useSelector((state) => state.language)
    const showClassrooms = classrooms && classrooms.length > 0 && classrooms[0].classroom_name
    const classroomsScores = showClassrooms && [...classrooms.map((clas) => clas.score)]
    const labels = showClassrooms && [...classrooms.map((clas) => clas.classroom_name[language])]

    return (
        <React.Fragment>
            <div
                className={`col-12 d-flex flex-column justify-content-center align-items-center col-print-4 ${
                    showClassrooms ? "col-lg-3" : "col-lg-4"
                }`}
                style={{ height: "200px" }}
            >
                <DoughnutChart data={sessionData} />
            </div>

            <div
                className={`col-12 d-flex justify-content-center col-print-4 ${showClassrooms ? "col-lg-3 col-md-6" : "col-lg-4"}`}
                style={{ maxHeight: "250px" }}
            >
                {printMode ? (
                    <HtmlBarChart dataSet={[sessionData.true_answers, sessionData.false_answers, sessionData.empty_answers]} />
                ) : (
                    <BarChart dataSet={[sessionData.true_answers, sessionData.false_answers, sessionData.empty_answers]} />
                )}
            </div>

            {showClassrooms && (
                <div className="col-12 col-md-6 col-lg-3  d-flex justify-content-center" style={{ maxHeight: "250px" }}>
                    {printMode ? (
                        <HtmlBarChart dataSet={classroomsScores} labels={labels} />
                    ) : (
                        <BarChart dataSet={classroomsScores} labels={labels} />
                    )}
                </div>
            )}
        </React.Fragment>
    )
}

export default ExamResultCard
