import React from "react"
import { useSelector } from "react-redux"
import { getTranslatedText as t } from "../../_locale"

const AccordionTable = ({ sessionData, alwaysOpen = false, showAverage = true }) => {
    const { language } = useSelector((state) => state.language)
    const total = {
        true_answers: 0,
        false_answers: 0,
        empty_answers: 0
    }

    if (showAverage) {
        Object.values(sessionData.course_results).forEach((course) => {
            total.true_answers += Object.values(course.subjects).reduce((acc, subject) => acc + subject.true_answers, 0)
            total.false_answers += Object.values(course.subjects).reduce((acc, subject) => acc + subject.false_answers, 0)
            total.empty_answers += Object.values(course.subjects).reduce((acc, subject) => acc + subject.empty_answers, 0)
        })
    }

    return (
        <>
            {Object.values(sessionData.course_results)
                .sort((a, b) => {
                    return a.name[language].localeCompare(b.name[language])
                })
                .map((course, courseIndex) => (
                    <React.Fragment key={courseIndex}>
                        <tbody id="accordion">
                            <tr className="bg-light table-row">
                                <td colSpan={10} className="text-left">
                                    <b> {course.name[language]}</b>
                                </td>
                                <td className="text-success font-weight-bold text-center grade-column">{t("t")}</td>
                                <td className="text-danger font-weight-bold text-center grade-column">{t("f")}</td>
                                <td className="text-primary font-weight-bold text-center grade-column">{t("e")}</td>
                                <td className="font-weight-bold text-center grade-column">{t('score')}</td>
                            </tr>
                        </tbody>

                        <tbody
                            className=" table-row"
                            id={`collapse ${course.name[language] + courseIndex}`}
                            data-parent="#accordion"
                        >
                            {!Array.isArray(course.subjects) &&
                                Object.values(course.subjects)
                                    .sort((a, b) => {
                                        return a.name[language].localeCompare(b.name[language])
                                    })
                                    .map((subject, subjectIndex) => (
                                        <React.Fragment key={subjectIndex}>
                                            <tr
                                                id={`collapse ${course.name[language] + courseIndex}`}
                                                data-toggle="collapse"
                                                data-target={`#collapse ${
                                                    subject.name[language] + courseIndex + subjectIndex
                                                }`}
                                                className="collapsed accordion table-row cursor-pointer"
                                            >
                                                <td colSpan={10} className="text-left pl-4">
                                                    {subject.name[language]}
                                                    {!Array.isArray(subject.outcomes) && !alwaysOpen && (
                                                        <b className="accordion-arrow"></b>
                                                    )}
                                                </td>
                                                <td className="text-center">
                                                    <b className="text-success">{subject.true_answers}</b>
                                                </td>
                                                <td className="text-center">
                                                    <b className="text-danger">{subject.false_answers}</b>
                                                </td>
                                                <td className="text-center">
                                                    <b className="text-primary">{subject.empty_answers}</b>
                                                </td>
                                                <td className="text-center">
                                                    <b>
                                                        {Math.round(
                                                            (subject.true_answers * 100) /
                                                                (subject.true_answers +
                                                                    subject.false_answers +
                                                                    subject.empty_answers)
                                                        )}
                                                    </b>
                                                </td>
                                            </tr>

                                            <>
                                                {!Array.isArray(subject.outcomes) &&
                                                    Object.values(subject.outcomes)
                                                        .sort((a, b) => {
                                                            return a.name[language].localeCompare(b.name[language])
                                                        })
                                                        .map((outcome, outcomeIndex) => (
                                                            <tr
                                                                id={`${alwaysOpen ? "show" : "collapse"} ${
                                                                    subject.name[language] + courseIndex + subjectIndex
                                                                }`}
                                                                className={`${alwaysOpen ? "show" : "collapse"}`}
                                                                key={outcomeIndex}
                                                            >
                                                                <td
                                                                    colSpan={10}
                                                                    className="text-left c-text-secondary pl-5"
                                                                >
                                                                    {outcome.name[language]}
                                                                </td>
                                                                <td className="text-center">
                                                                    <b className="text-success">
                                                                        {outcome.true_answers}
                                                                    </b>
                                                                </td>
                                                                <td className="text-center">
                                                                    <b className="text-danger">
                                                                        {outcome.false_answers}
                                                                    </b>
                                                                </td>
                                                                <td className="text-center">
                                                                    <b className="text-primary">
                                                                        {outcome.empty_answers}
                                                                    </b>
                                                                </td>
                                                                <td className="text-center">
                                                                    <b>
                                                                        {Math.round(
                                                                            (outcome.true_answers * 100) /
                                                                                (outcome.true_answers +
                                                                                    outcome.false_answers +
                                                                                    outcome.empty_answers)
                                                                        )}
                                                                    </b>
                                                                </td>
                                                            </tr>
                                                        ))}
                                            </>
                                        </React.Fragment>
                                    ))}
                            {showAverage && (
                                <tr className="bg-dark text-light">
                                    <td colSpan={10}>{t("total")}</td>
                                    <td className="text-center">
                                        <b className="text-success">{total.true_answers}</b>
                                    </td>
                                    <td className="text-center">
                                        <b className="text-danger">{total.false_answers}</b>
                                    </td>
                                    <td className="text-center">
                                        <b className="text-primary">{total.empty_answers}</b>
                                    </td>
                                    <td className="text-center">
                                        {/* calculate score */}
                                        <b>
                                            {Math.round(
                                                (total.true_answers /
                                                    (total.true_answers + total.false_answers + total.empty_answers)) *
                                                    100
                                            )}
                                        </b>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </React.Fragment>
                ))}
        </>
    )
}

export default AccordionTable
