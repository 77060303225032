import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js/auto"
import { utils } from "../../_helpers/utils"
import { getTranslatedText as t } from "../../_locale"
import { examServices } from "../../services/exam.services"
import EvaluationCard from "../partials/EvaluationCard"
import Spinner from "../partials/Spinner"
import Percent from "../../styles/icons/Percent"
import User from "../../styles/icons/User"
import Users from "../../styles/icons/Users"
import AccordionTable from "./AccordionTable"
import ExamResultCard from "./ExamResultCard"
import SectionHeader from "./SectionHeader"
import Header from "../main/Header"
import Logo from "../../styles/img/logo.png"

ChartJS.register(ArcElement, Tooltip, Legend)

const PdfReport = () => {
    const { exam_code, grade_id } = useParams()
    const { language } = useSelector((state) => state.language)
    const [result, setResult] = useState(null)
    const [gradeResult, setGradeResult] = useState({})

    useEffect(() => {
        if (localStorage.auth_token === "null" || localStorage.auth_token === undefined) {
            window.location = "/login"
        } else {
            if (result === null || Object.keys(result).length === 0) {
                examServices.getPdfReport(exam_code).then((data) => {
                    setResult(data)
                })
            }

            if (result && result.exam_code !== exam_code) {
                examServices.getPdfReport(exam_code).then((data) => {
                    setResult(data)
                })
            }
        }
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (result === null || Object.keys(result).length === 0) {
            return
        }

        if (!grade_id) {
            setGradeResult(null)
            return
        }

        result.data.forEach((grade) => {
            if (parseInt(grade.classroom_id) === parseInt(grade_id)) {
                setGradeResult(grade)
            }
        })
    }, [grade_id, result])

    const getScoreTable = (student) => {
        let data = {
            student: student,
            score: student.score,
            rank: student.rank,
            participants_count: student.participants_count,
            course_results: student.results.map((course) => {
                return {
                    name: course.course_name,
                    subjects: course.result.subjects
                }
            })
        }

        return (
            <div className="bg-white p-3 p-sm-5 rounded mb-5">
                <table className="table">
                    <AccordionTable sessionData={data} alwaysOpen={true} />
                </table>
            </div>
        )
    }

    const getCharts = (student, grade) => {
        return (
            <div className="row">
                <div className="col-12 mb-5">
                    <div className="mb-lg-0 bg-white rounded">
                        <div className="p-5">
                            <div className="h-100">
                                <h3>{`${student.student.name} ${student.student.surname}`}</h3>
                                {grade && grade.classroom_name && (
                                    <h5 className="text-muted mb-4 mb-5">{grade.classroom_name[language]}</h5>
                                )}
                                <div className="row">
                                    <div className="col-12 col-lg-4 col-print-4 mb-5">
                                        <div className="row">
                                            {student.success_rate !== null && student.success_rate !== undefined && (
                                                <div className="col-12">
                                                    <EvaluationCard
                                                        text={t("percentileRank")}
                                                        value={student.success_rate}
                                                        className="mb-5"
                                                        children={<Percent fill="#ff9f43" />}
                                                        childClassName="bg-light-orange"
                                                    />
                                                </div>
                                            )}
                                            {student.rank !== null && student.rank !== undefined && (
                                                <div className="col-12">
                                                    <EvaluationCard
                                                        text={t("position")}
                                                        value={student.rank}
                                                        className="mb-5"
                                                        children={<User stroke="#00cfe8" />}
                                                        childClassName="bg-light-blue"
                                                    />
                                                </div>
                                            )}
                                            {student.participants_count !== null &&
                                                student.participants_count !== undefined && (
                                                    <div className="col-12">
                                                        <EvaluationCard
                                                            text={t("participation")}
                                                            value={student.participants_count}
                                                            children={<Users fill="#7367f0" />}
                                                            childClassName="bg-light-purple"
                                                        />
                                                    </div>
                                                )}
                                        </div>
                                    </div>

                                    <ExamResultCard sessionData={student} printMode={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="h-100">
            <Header hidePrint={true} />
            <Spinner />

            <button
                className="btn btn-primary d-print-none font-size-16"
                onClick={() => {
                    window.print()
                }}
                style={{ position: "fixed", top: "75px", right: "20px", zIndex: "9999" }}
            >
                <i className="fa fa-print mr-2"></i>
                {t("print")}
            </button>

            {utils.objectHasLength(result) && (
                <div className="main-content-report-wrapper">
                    <div className="result pb-3 p-sm-4 p-lg-5 no-margin">
                        <SectionHeader
                            className="d-print-none"
                            primaryData={result.exam.name}
                            secondaryData={
                                gradeResult && utils.objectHasLength(gradeResult)
                                    ? gradeResult.classroom_name["tr"]
                                    : ""
                            }
                        />

                        {result.data.map(
                            (grade) =>
                                (!grade_id || parseInt(grade.classroom_id) === parseInt(grade_id)) && (
                                    <div key={grade.classroom_id}>
                                        <div className="col px-0">
                                            {grade &&
                                                grade.students.map((student) => (
                                                    <div key={student.student.email}>
                                                        <img src={Logo} alt="ApiTwist" className="only-print text-center mx-auto mb-4" style={{width: "300px"}}/>
                                                        <div className="col px-0 print-page print-break">
                                                            {getCharts(student, grade)}
                                                            {getScoreTable(student)}
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                )
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default PdfReport
