import React from "react"
import { Bar } from "react-chartjs-2"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js/auto"
import { getTranslatedText as t } from "../../../_locale"
ChartJS.register(ArcElement, Tooltip, Legend)

const BarChart = ({ dataSet, labels = [t("true"), t("false"), t("empty")] }) => {
    // max value will be the rounded up value of the highest value in the dataset + 5
    const maxValue = Math.ceil(Math.max(...dataSet) / 5) * 5 + 5
    const verticalBarLabels = {
        id: "verticalBarLabels",
        afterDatasetsDraw(chart, args, plugins) {
            const { ctx, data } = chart

            ctx.save()
            chart.getDatasetMeta(0).data.forEach((datapoint, index) => {
                ctx.font = "bold 14px sans-serif"
                ctx.fillStyle = data.datasets[0].borderColor[index]
                ctx.textAlign = "center"
                ctx.fillText(data.datasets[0].data[index], datapoint.x, datapoint.y - 15)
            })
        }
    }
    return (
        <Bar
            data={{
                labels: labels,
                datasets: [
                    {
                        label: t("answers"),
                        data: dataSet,
                        borderWidth: 1,
                        backgroundColor: ["rgb(75, 192, 192)", "rgb(255, 99, 132)", "rgb(255, 206, 86)"],
                        borderColor: ["rgb(75, 192, 192)", "rgb(255, 99, 132)", "rgb(255, 206, 86)"]
                    }
                ]
            }}
            options={{
                plugins: {
                    title: {
                        display: true,
                        padding: {}
                    },
                    tooltip: {
                        enabled: false
                    },
                    legend: {
                        display: false
                    }
                },
                maintainAspectRatio: false,
                scales: {
                    x: {
                        grid: {
                            display: false
                        }
                    },

                    y: {
                        beginAtZero: true,
                        grace: 1,
                        grid: {
                            display: false
                        },

                        min: 0,
                        max: maxValue,
                        ticks: {
                            callback: function (value, index, values) {
                                return index === 0 || index === values.length - 1 ? value : ""
                            }
                        }
                    }
                }
            }}
            plugins={[verticalBarLabels]}
        />
    )
}

export default BarChart