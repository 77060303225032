import React from "react"

const SectionHeader = ({ primaryData, secondaryData, tertiaryData, className }) => {
    return (
        <div className={`py-3 mb-3 d-flex align-items-center section-header ${className ?? ""}`}>
            {primaryData && <h4>{primaryData}</h4>}

            {secondaryData && (
                <>
                    <div
                        style={{
                            width: "2px",
                            background: "lightGray",
                            height: "20px",
                            marginInline: "10px"
                        }}
                    ></div>
                    <span className="text-small text-muted">{secondaryData}</span>
                </>
            )}

            {tertiaryData && (
                <>
                    <div
                        style={{
                            width: "2px",
                            background: "lightGray",
                            height: "20px",
                            marginInline: "10px"
                        }}
                    ></div>
                    <span className="text-small text-muted">{tertiaryData}</span>
                </>
            )}
        </div>
    )
}

export default SectionHeader
