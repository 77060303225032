import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { getTranslatedText as t } from "../../_locale"
import { languageActions } from "../../actions/language.actions"
import { userActions } from "../../actions/user.actions"
import LogOut from "../../styles/icons/Logout"
import MobileLogo from "../../styles/img/logo-mobile.png"
import Logo from "../../styles/img/logo.png"

const Header = ({hidePrint = false}) => {
    let history = useHistory()
    const dispatch = useDispatch()
    const { user, loading, loggedIn } = useSelector((state) => state.user)
    const { language } = useSelector((state) => state.language)
    const logOutHandler = (e) => {
        e.preventDefault()
        dispatch(userActions.logout())
    }

    const changeLocale = (locale) => {
        dispatch(languageActions.changeLanguage(locale))
    }

    useEffect(() => {
        if (!user && loading === false && loggedIn === false) {
            localStorage.auth_token = null
            setTimeout(() => {
                history.push("/login")
            }, 300)
        }
    }, [user, loggedIn, loading])

    return (
        <header className={`header-area ${hidePrint ? "d-print-none" : ""}`}>
            <div className="container-fluid">
                <div className="header-wrap">
                    <a href="/" className="logo d-none d-md-block">
                        <img src={Logo} alt="ApiTwist" />
                    </a>
                    <a href="/" className="logo d-md-none">
                        <img src={MobileLogo} alt="ApiTwist" />
                    </a>


                    <div className="d-flex align-items-center right">
                        <div className="lang">
                            <span className="active text-uppercase">{language}</span>

                            <ul className="lang_menu">
                                <li
                                    className={language == "en" ? "active" : ""}
                                    onClick={() => {
                                        changeLocale("en")
                                    }}
                                >
                                    <span className="lang_shortcode">EN</span> {t("english")}
                                </li>
                                <li
                                    className={language === "tr" ? "active" : ""}
                                    onClick={() => {
                                        changeLocale("tr")
                                    }}
                                >
                                    <span className="lang_shortcode">TR</span> {t("turkish")}
                                </li>
                            </ul>
                        </div>
                        <ul className="user-part">
                            {/* <li>
                            <a href="#">
                            <img src={Bell} alt="" />
                            </a>
                            <ul className="submenu notification">
                            <li>
                            <a href="#" className="white">
                            Size 2 sınav atandı. <span>1 gün önce</span>
                            </a>
                            </li>
                            <li>
                            <a href="#" className="white">
                            Rehberinizden mesaj var. <span>3 gün önce</span>
                            </a>
                            </li>
                            <li>
                            <a href="#">
                            Rehberinizden mesaj var. <span>30.10.2019</span>
                            </a>
                            </li>
                            </ul>
                            </li> */}

                            {user && (
                                <li>
                                    <p>{user.name} {user.surname}</p>
                                </li>
                            )}
                            <li className="user-img shadow ml-3">
                                <a href="#">
                                    <span>
                                        {user &&
                                            user.name.charAt(0) +
                                                (user.surname && user.surname !== null ? user.surname.charAt(0) : "")}
                                    </span>
                                </a>
                                <ul className="submenu">
                                    {/* <li>
                                    <div className="user-img">
                                    <h4>{user.name} </h4>
                                    <p className="mail">{user.email} </p>
                                    <button className="box-btn style-2">Profilim</button> 
                                    </div>
                                    </li>*/}
                                    <li className="submenu-item" onClick={logOutHandler}>
                                        <LogOut className="mr-2" /> {t("logout")}
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header
