import { axiosInstance } from "./axiosInstances"

export const examServices = {
    getAll,
    getResult,
    getPdfReport
}

function getAll() {
    return new Promise((resolve, reject) => {
        axiosInstance
            .get("exams")
            .then((res) => {
                const data = res.data.data
                resolve(data)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

function getResult(exam_code) {
    return new Promise((resolve, reject) => {
        axiosInstance
            .get(`exams/${exam_code}/result`)
            .then((res) => {
                const data = res.data.data
                resolve(data)
            })
            .catch((err) => {
                reject(err)
            })
    })
}


function getPdfReport(exam_code) {
    return new Promise((resolve, reject) => {
        axiosInstance
            .get(`exams/${exam_code}/pdfReport`)
            .then((res) => {
                const data = res.data.data
                resolve(data)
            })
            .catch((err) => {
                reject(err)
            })
    })
}
