import React from "react"
import { Doughnut } from "react-chartjs-2"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"
import { getTranslatedText as t } from "../../../_locale"

ChartJS.register(ArcElement, Tooltip, Legend)

const DoughnutChart = ({ data }) => {
    const getGradient = (chart) => {
        const {
            ctx,
            chartArea: { top, bottom, left, right }
        } = chart

        const gradientSegment = ctx.createLinearGradient(left, 0, right, 0)
        gradientSegment.addColorStop(0, "#dddefc")
        gradientSegment.addColorStop(1, "#7367f0")

        return gradientSegment
    }

    const getScore = (data) => {
        if(!data) {
            return [0, 100]
        }

        if (data.score !== undefined && data.score !== null) {
            return [parseInt(data.score), 100 - parseInt(data.score)]
        }

        let scorePerQuestion = parseFloat(100 / data.question_count).toFixed(2);
        let score = Math.round(data.true_answers * scorePerQuestion)

        return [score, 100 - score]
    }

    const doughnutData = {
        labels: [t("score")],
        datasets: [
            {
                label: t("score"),
                data: getScore(data),
                backgroundColor: (context) => {
                    const chart = context.chart
                    const { ctx, chartArea } = chart

                    if (!chartArea) {
                        return null
                    }
                    
                    if (context.dataIndex === 0) {
                        return getGradient(chart)
                    } else {
                        return "transparent"
                    }
                },
                rotation: 270,
                cutout: "80%"
            }
        ]
    }

    const doughnutLabel = {
        id: "doughnutLabel",
        afterDatasetsDraw(chart, args, plugins) {
            const { ctx, data } = chart
            const centerX = chart.getDatasetMeta(0).data[0].x
            const centerY = chart.getDatasetMeta(0).data[0].y

            ctx.save()

            ctx.font = "14px sans-serif"
            ctx.fillStyle = "#8e909c"
            ctx.textAlign = "center"
            ctx.textBaseline = "middle"
            ctx.fillText(t("score"), centerX, centerY - 35)

            ctx.font = "48px sans-serif"
            ctx.fillStyle = "#5d596c"
            ctx.textAlign = "center"
            ctx.textBaseline = "middle"

            ctx.fillText(data.datasets[0].data[0], centerX, centerY)
        }
    }

    const options = {
        plugins: {
            tooltip: {
                enabled: false
            },
            legend: {
                display: false
            }
        }
    }

    return <Doughnut data={doughnutData} options={options} plugins={[doughnutLabel]}></Doughnut>
}

export default DoughnutChart
