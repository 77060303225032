import React from "react"
import { getTranslatedText as t } from "../../../_locale"

const HtmlBarChart = ({
    dataSet,
    labels = [t("true"), t("false"), t("empty")],
    colors = ["rgb(75, 192, 192)", "rgb(255, 99, 132)", "rgb(255, 206, 86)"]
}) => {
    return (
        <>
            {dataSet && (
                <ul className="chart">
                    {dataSet.map((item, index) => (
                        <li
                            key={index}
                            style={{
                                gridColumn: `${index + 1}`,
                                height: `${(item / Math.max(...dataSet)) * 100}%`,
                                backgroundColor: colors[index] ?? ""
                            }}
                        >
                            {labels[index] ?? ""} <span>{item}</span>
                        </li>
                    ))}
                </ul>
            )}
        </>
    )
}

export default HtmlBarChart
